@import url('https://fonts.googleapis.com/css2?family=Comfortaa&display=swap');

*{
  margin: 0px;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body{
  font-family: 'Comfortaa', cursive;
}

body::-webkit-scrollbar {
  width: 0.25rem;
}

body::-webkit-scrollbar-track {
  background: #1a2238;
}

body::-webkit-scrollbar-thumb {
  background: #f4db7d;
}

.enterprises{
  color: #f03f2b;
}

.copyrightDiv{
  background-color: #cf2d2d;
  color:#fff;
  padding: 13px 0px;
  /* margin-top: 20px; */
}