section {
  min-height: 95vh;
  max-height: 90vh !important;
  /* background-color: #3c403d; */
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0px 50px;
}

.bg {
  width: 45%;
  margin-left: 100px;
}

.pcontent {
  /* background-color: red; */
  padding-right: 250px;
  font-size: 1.2rem;
  text-align: justify;
}

.sectionTitle {
  font-size: 3rem;
  font-weight: 800;
}

.services {
  background-color: #319ad6;
  color: #fff;
  padding: 30px 10px;
}

.servicecard {
  background-color: #319ad6;
  border: 4px solid #fff;
  padding: 20px 0px;
  border-radius: 15px;
}

.iconSection {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardIcon {
  width: 100px;
  padding: 25px 20px;
  border-radius: 100px;
  background-color: #fff;
}

.cardIcon i {
  color: #319ad6;
}

.cctv {
  width: 80%;
}

.readMore {
  cursor: pointer;
  border: 3px solid #fff;
  padding: 10px 0px;
  border-radius: 10px;
}

.readMore:hover {
  color: #319ad6;
  background-color: #fff;
}

.companyRight {
  padding: 30px 0px;
  background-color: #31d665;
  color: #fff;
}

.companyRightText {
  margin: 20px 0px;
  cursor: pointer;
}

.peninsula {
  background-color: #1f1f1f;
}

.customersArea .card{
  border: 0px;
}

.customersArea,
.customerSection,
.dealerArea{
  display: flex;
  align-items: center;
  justify-content: center;
}

.dealerArea {
  background-color: #fff;
}

.dealerArea .card {
  border: 0px;
}

.meetus,
.team,
.about-us {
  padding: 10px 20px;
}

.meetus{
  background-color: #202A44;
  color: #fff;
}

.nonMapArea{
  padding-top: 20px;
}
.contactArea{
  padding-left: 100px;
}

.address,
.mail,
.phoneNumber{
  font-size: 1.2rem;
  font-weight: 500;
  color: #fff;
  text-decoration: none;
}

.address{
  font-size: 1rem;
}

.phoneNumber:hover,
.mail:hover{
  color: #ffd413;
}

.contactIcon2,
.contactIcon{
  font-size: 3rem;
  padding: 25px;
  border: 1px solid #fff;
  border-radius: 100px;
}

.contactIcon2{
  padding: 25px 32px;
}
.about,
.aboutUsPar {
  display: flex;
  align-items: center;
  justify-content: center;
}


.aboutUsPar p {
  text-align: start;
  font-size: 1.3rem;
  text-align: justify;
}
.aboutUsImg {
  width: 100%;
}

.otherDeal {
  width: 80%;
}
/* .border{
  border: 1px solid #000;
} */
.canon {
  width: 100%;
}
.canon2 {
  width: 80%;
}
.canon3 {
  width: 60%;
}
.canon4{
  width: 120%;
}
.canon5{
  width: 130%;
}
.canon6{
  width: 130%;
}
.teamRow {
  display: flex;
  justify-content: center;
}
.teamRow .card {
  border: 0px;
}
.team2 {
  width: 60%;
}
.team1{
  width: 65%;
}
.team3 {
  width: 80%;
}
.map-responsive {
  overflow: hidden;
  padding-bottom: 30.25%;
  position: relative;
  height: 0;
}

.map-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
@media (max-width: 800px) {
  .bg {
    width: 100%;
    position: absolute;
    top: 50;
    right: 0;
    opacity: 0.2;
  }

  section {
    padding: 0px 10px;
  }
  .sectionTitle{
    font-size: 2rem;
  }
  .content {
    padding: 0px !important;
    max-width: 400px;
    z-index: 10;
  }

  .pcontent{
    padding: 0px;
  }

  .card {
    margin: 10px 0px;
  }
  .map-responsive {
    margin-top: 50px;
    overflow: hidden;
    padding-bottom: 100.25%;
    position: relative;
    height: 0;
  }

  .canon,
  .canon2,
  .canon3,
  .canon4,
  .canon5,
  .canon6{
    width: 100%;
  }
}
