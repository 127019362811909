.navSec {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
}
.navBack {
  /* position: sticky;
    top: 0;
    min-width: 100%;
    max-width: 100%;
    background-color: #000;
    opacity: 0.75;
    z-index: 1; */
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  opacity: 0.75;
}
.navbarDiv {
  position: sticky;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 50px;
  /* border-bottom: 2px solid #3262a8; */
  margin-bottom: 10px;
  z-index: 2;
}

.navbarBtn {
  position: fixed;
  top: 5px;
  right: 20px;
  background-color: #a83232;
  color: #fff;
  padding: 14px 18.5px;
  border-radius: 100px;
  z-index: 10000 !important;
}

.navbarBtn:hover {
  background-color: #7c77dd;
  cursor: pointer;
}

.navigation {
  position: fixed;
  bottom: 0;
  /* right: 30px; */
  left: 100%;
  width: 20%;
  height: 100%;
  background: #7c77dd;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 1s;
  cursor: auto;
  visibility: hidden;
  opacity: 0;
}

.navigation-style-1,
.navigation-style-2,
.navigation-style-3 {
  position: fixed;
  bottom: 0;
  /* right: 30px; */
  /* right: 100%; */
  left: 100%;
  width: 20%;
  height: 100%;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 1.3s;
  cursor: auto;
  visibility: hidden;
  opacity: 0;
}

.navigation-style-1.active,
.navigation-style-2.active,
.navigation-style-3.active,
.navigation.active {
  visibility: visible;
  opacity: 1;
  bottom: 0;
  left: 80%;
  transition: 1.3s;
}

.navigation-style-1 {
  background-color: #ce3333;
}
.navigation-style-2 {
  background-color: #ffd413;
}
.navigation-style-3 {
  background-color: #31c7a4;
}
.navigation ul {
  position: relative;
  list-style: none;
  text-align: center;
}

.navigation ul li input {
  border: none;
  background-color: #7c77dd;
}
.navigation ul li input,
.navigation ul li a {
  font-size: 2.5em;
  color: #fff;
  text-decoration: none;
  font-weight: 300;

  -webkit-transition: font-size 0.5s;
}
.navigation ul li input:hover {
  color: #f2c94c;
  font-size: 3.5em;
  font-weight: 600;
  transition: 0.5s;

  -webkit-transition: font-size 0.5s;
}

@media (max-width: 770px) {
  .navigation-style-1,
  .navigation-style-2,
  .navigation-style-3,
  .navigation {
    width: 100%;
  }
  .navigation-style-1.active,
  .navigation-style-2.active,
  .navigation-style-3.active,
  .navigation.active {
    visibility: visible;
    opacity: 1;
    bottom: 0;
    left: 0;
    transition: 1.3s;
  }
  .navigation ul li a {
    font-size: 2em;
    color: #111;
    text-decoration: none;
    font-weight: 200;

    -webkit-transition: font-size 0.5s;
  }
  .navigation ul li a:hover {
    color: #f60f20;
    font-size: 3em;
    font-weight: 500;
    transition: 0.5s;

    -webkit-transition: font-size 0.5s;
  }
}
